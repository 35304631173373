/* eslint-disable react/jsx-curly-brace-presence */
import React, { useContext } from 'react';
import {
	Box,
	Container,
	Grid,
	IconButton,
	Link,
	Stack,
	Typography,
} from '@mui/material';
import { Mail, Phone, Place } from '@mui/icons-material';
import './Footer.css';
import CustomizationContext from '../../Context/Customization/CustomizationContext';
import DynamicIconButton from '../DynamicIconButton/DynamicIconButton';
import PoweredBy from '../PoweredBy/PoweredBy';
import FormatPhoneNumber from '../../Utils/PhoneNumberFormatter';

export default function Footer() {
	const {
		textColorFooter,
		address,
		email,
		phone,
		socialMedia,
		companyLogoFooter,
	} = useContext(CustomizationContext);

	return (
		<div>
			<Box
				sx={{
					backgroundColor: 'backgroundColorFooter.main',
					minHeight: 150,
					boxShadow: '0px -2px 8px 0px rgba(0,0,0,0.40)',
				}}
				data-testid='footer'
			>
				<Container
					maxWidth='lg'
					sx={{
						margin: '0 auto',
					}}
				>
					<Grid
						container
						spacing={{ xs: 2, md: 3 }}
						columns={{ xs: 4, sm: 8, md: 12 }}
						sx={{ alignItems: 'flex-start', justifyContent: 'center' }}
					>
						{companyLogoFooter && (
							<Grid
								item
								xs={12}
								sm={12}
								md={4}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									alignSelf: 'center',
								}}
								data-testid='footer_img'
							>
								<img
									src={companyLogoFooter}
									height={80}
									alt={companyLogoFooter}
									data-testid='footer_img_logo'
								/>
							</Grid>
						)}
						{socialMedia.length !== 0 && (
							<Grid
								item
								xs={12}
								sm={12}
								md={4}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
								}}
								data-testid='footer_socialMedia'
							>
								<>
									<Typography variant='h6' color='textColorFooter.main'>
										Redes Sociales
									</Typography>
									<Stack
										direction='row'
										flexWrap='wrap'
										justifyContent='center'
									>
										{socialMedia.map(({ name, _id, link, icon, isSvg }) => (
											<IconButton
												aria-label={name}
												size='large'
												key={_id}
												href={link}
												target='_blank'
												data-testid={`footer_socialMedia_button_${name}`}
												sx={{
													maxWidth: '48px ',
												}}
											>
												{icon && (
													<DynamicIconButton
														fontSize='inherit'
														color='textColorFooter'
														iconName={icon}
														isSvg={isSvg}
														textColorFooter={textColorFooter}
													/>
												)}
											</IconButton>
										))}
									</Stack>
								</>
							</Grid>
						)}
						{phone && email && address && (
							<Grid
								item
								xs={2}
								sm={4}
								md={4}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									pb: '16px',
								}}
								data-testid='footer_contact'
							>
								<Typography variant='h6' color='textColorFooter.main'>
									Contacto
								</Typography>
								<Stack
									sx={{
										justifyContent: 'center',
										alignItems: 'flex-start',
										flexWrap: 'wrap',
										maxWidth: 'fit-content',
									}}
									flexDirection='column'
									// spacing={3}

									spacing={0.5}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Phone fontSize='inherit' color='textColorFooter' />
										<Link
											href={`tel:${phone}`}
											color='inherit'
											underline='none'
											data-testid='footer_contact_phone'
										>
											<Typography
												variant='h7'
												color='textColorFooter.main'
												data-testid='footer_contact_phone_label'
											>
												{FormatPhoneNumber(phone) || null}
											</Typography>
										</Link>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Mail fontSize='inherit' color='textColorFooter' />
										<Link
											href={`mailto:${email}`}
											color='inherit'
											underline='none'
											data-testid='footer_contact_email'
										>
											<Typography
												variant='h7'
												color='textColorFooter.main'
												data-testid='footer_contact_email_label'
											>
												{email || null}
											</Typography>
										</Link>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'flex-start',
										}}
									>
										<Place fontSize='inherit' color='textColorFooter' />
										<Typography
											variant='h7'
											color='textColorFooter.main'
											data-testid='footer_contact_address_label'
											sx={{
												width: '100% !important',
												textWrap: 'balance',
											}}
										>
											{address || null}
										</Typography>
									</Box>
								</Stack>
							</Grid>
						)}
					</Grid>
				</Container>
			</Box>
			<PoweredBy />
		</div>
	);
}
