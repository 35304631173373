import { Container, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Element } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import BillingFormCard from '../../Components/BillingFormCard/BillingFormCard';
import ButtonToTop from '../../Components/ButtonToTop/ButtonToTop';
import FABMenu from '../../Components/FABMenu/FABMenu';
import FAQs from '../../Components/FAQs/FAQs';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import SideBar from '../../Components/SideBar/SideBar';
import Welcome from '../../Components/Welcome/Welcome';
import 'react-toastify/dist/ReactToastify.css';

import './Main.css';

export default function Main() {
	const [sidebar, setSidebar] = useState(false);

	const toogleSidebar = () => {
		setSidebar(!sidebar);
	};

	const theme = useTheme();
	const windowWidth = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<div>
			<ToastContainer />
			<Header toogleSidebar={toogleSidebar} />
			<SideBar sidebar={sidebar} toogleSidebar={toogleSidebar} />
			{windowWidth ? null : <ButtonToTop />}
			{windowWidth ? null : <FABMenu />}
			<Container
				maxWidth='lg'
				sx={{
					margin: '0 auto',
				}}
			>
				<Element name='home' className='home'>
					<Welcome />
				</Element>
				<Element name='billing' className='billing'>
					<BillingFormCard />
				</Element>
				<Element name='FAQs' className='FAQs'>
					<FAQs />
				</Element>
			</Container>
			<Element name='contact' className='contact'>
				<Footer />
			</Element>
		</div>
	);
}
