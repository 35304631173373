import React, { useContext } from 'react';
import {
	Container,
	Box,
	Stepper,
	Step,
	StepLabel,
	Button,
	Tooltip,
} from '@mui/material';
import { scroller } from 'react-scroll';

import TicketData from '../TicketData/TicketData';
import BillingData from '../BillingData/BillingData';
import BillingContext from '../../Context/Billing/BillingContext';
import ConfirmationTable from '../ConfirmationTable/ConfirmationTable';
import WarningMessage from '../WarningMessage/WarningMessage';
import BillingFinished from '../BillingFinished/BillingFinished';

const steps = ['Datos del ticket', 'Datos de facturación', 'Resumen'];

export default function BillingStepper() {
	const activeStepContent = [
		<TicketData />,
		<BillingData />,
		<ConfirmationTable />,
	];
	const {
		activeStep,
		handleReset,
		invoiceStatus,
		captchaRef,
		onChangeCaptchaValidation,
	} = useContext(BillingContext);

	return (
		<Box sx={{ width: '100%' }}>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				orientation='horizontal'
				data-testid='billingStepper'
			>
				{activeStep === steps.length
					? null
					: steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps} data-testid={label}>
										{label}
									</StepLabel>
								</Step>
							);
					  })}
			</Stepper>
			{activeStep === steps.length ? (
				<>
					<BillingFinished />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							pt: 2,
							justifyContent: 'center',
						}}
					>
						<Tooltip
							title={!invoiceStatus ? 'Su solicitud se está procesando...' : ''}
							followCursor
						>
							<span>
								<Button
									variant='contained'
									onClick={() => {
										handleReset();
										captchaRef.current.reset();
										onChangeCaptchaValidation(false);
										scroller.scrollTo('billingFinished', {
											duration: 500,
											delay: 0,
											smooth: 'easeInOutQuart',
										});
									}}
									disabled={!invoiceStatus}
									sx={{ mr: 1 }}
								>
									Hacer otra factura
								</Button>
							</span>
						</Tooltip>
					</Box>
				</>
			) : (
				<>
					<Container>{activeStepContent[activeStep]}</Container>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<WarningMessage />
					</Box>
				</>
			)}
		</Box>
	);
}
