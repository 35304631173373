/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState, useEffect } from 'react';
import {
	FormControlLabel,
	FormLabel,
	Grid,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { object, string, ref } from 'yup';
import BillingContext from '../../Context/Billing/BillingContext';
import './BillingData.css';
import StepperButtons from '../StepperButtons/StepperButtons';
import LabelWTooltip from '../LabelWTooltip/LabelWTooltip';
import SvgComponent from '../../Utils/SvgComponent';
import { SvgIcons } from '../../Utils/Constants';

export default function BillingData() {
	const {
		initialValues,
		taxRegimes,
		CFDIuse,
		getTaxRegime,
		getCfdiUses,
		resetCfdiUses,
	} = useContext(BillingContext);

	const [rfcType, setRfcType] = useState(initialValues.tipoDePersona);
	const [convertirMayusculas, setConvertirMayusculas] = useState(true);
	const [mayus, setMayus] = useState('mayus');

	const tooltipContent = [
		{
			id: 0,
			value:
				'Nombre o razón social del receptor sin régimen societario tal cual como se muestra en la constancia de situación fiscal.',
		},
		{
			id: 1,
			value: (
				<>
					Ejemplo:
					<ul>
						<li>
							<div>"Mi empresa S.A. de C.V." ❌</div>
						</li>
						<li>
							{' '}
							<div>"MI EMPRESA" ✅</div>
						</li>
					</ul>
				</>
			),
		},
		{ id: 2, value: 'Máximo 250 caracteres' },
	];

	const toggleConvertirMayusculas = (e, checked) => {
		setMayus(checked);
		setConvertirMayusculas(!convertirMayusculas);
	};

	return (
		<Grid
			container
			spacing={2}
			className='BillingData__container'
			sx={{ display: 'flex', justifyContent: 'center' }}
		>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography variant='h5' sx={{ textAlign: 'center' }}>
					Datos de facturación
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={object({
						tipoDePersona: string(),
						rfc: string()
							.required('Es un campo requerido')
							.min(
								rfcType === 'F' ? 13 : 12,
								`El RFC debe contener ${
									rfcType === 'F' ? '13 ' : '12'
								} carácteres`
							)
							.matches(
								/^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
								'El RFC no tiene un formato correcto'
							),
						nombre: string().required('Es un campo requerido'),
						codigoPostal: string()
							.required('Es un campo requerido')
							.min(5, 'El código postal debe contener maximo 5 carácteres'),
						regimenFiscal: string().required('Es un campo requerido'),
						CFDI: string().required('Es un campo requerido'),
						correo: string()
							.required('Es un campo requerido')
							.email('No es un correo electrónico valido'),
						correoConfirmacion: string()
							.required('Es un campo requerido')
							.email('No es un correo electrónico valido')
							.oneOf([ref('correo'), null], 'No coincide con el correo'),
					})}
					validateOnMount
				>
					{({
						errors,
						isValid,
						touched,
						dirty,
						values,
						setFieldValue,
						validateForm,
					}) => (
						<Form>
							{useEffect(() => {
								getTaxRegime(values.tipoDePersona);
							}, [values.tipoDePersona])}
							{useEffect(() => {
								getCfdiUses(values.regimenFiscalKey);
							}, [values.regimenFiscalKey])}
							<Grid
								item
								container
								spacing={2}
								className='BillingData__container'
							>
								<Grid
									item
									container
									xs={12}
									md={6}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<FormLabel required>Tipo de persona</FormLabel>
									<Field name='tipoDePersona'>
										{({ field, form }) => (
											<RadioGroup
												{...field}
												row
												sx={{
													justifyContent: 'space-around',
													padding: { md: '7px 0 7px 0' },
												}}
												onChange={(event) => {
													form.setFieldValue(
														'tipoDePersona',
														event.currentTarget.value
													);
													setRfcType(event.currentTarget.value);
													form.setFieldValue('rfc', '');
													form.setFieldValue('CFDI', '');
													form.setFieldValue('regimenFiscal', '');
													validateForm();
													resetCfdiUses();
												}}
											>
												<FormControlLabel
													data-testid='radioFisica'
													value='F'
													control={<Radio />}
													label='Física'
													onClick={() => {
														form.setFieldValue('tipoDePersonaText', 'Física');
													}}
												/>
												<FormControlLabel
													data-testid='radioMoral'
													value='M'
													control={<Radio />}
													label='Moral'
													onClick={() => {
														form.setFieldValue('tipoDePersonaText', 'Moral');
													}}
												/>
											</RadioGroup>
										)}
									</Field>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormLabel required>RFC</FormLabel>

									<Field
										data-testid='RFC'
										name='rfc'
										type='string'
										as={TextField}
										value={values.rfc.split(' ').join('')}
										onChange={(e) => {
											setFieldValue('rfc', e.target.value.toUpperCase());
										}}
										fullWidth
										placeholder='RFC'
										inputProps={{
											maxLength: values.tipoDePersona === 'F' ? '13' : '12',
											style: { textTransform: 'uppercase' },
										}}
										error={errors.rfc && touched.rfc}
										helperText={touched.rfc && errors.rfc}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<LabelWTooltip
										required
										label='Nombre o razón social'
										tooltipContent={tooltipContent}
									/>

									<Field
										data-testid='nombreInput'
										name='nombre'
										as={TextField}
										value={values.nombre}
										fullWidth
										placeholder='Nombre o razón social'
										error={errors.nombre && touched.nombre}
										helperText={touched.nombre && errors.nombre}
										inputProps={{ maxLength: 250 }}
										onChange={(e) => {
											const inputValue = e.target.value;
											if (convertirMayusculas) {
												setFieldValue('nombre', inputValue.toUpperCase());
											} else {
												setFieldValue('nombre', inputValue);
											}
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position='end'
													data-testid='nombreInputInputAdornment'
												>
													<Tooltip
														data-testid='nombreInputTooltip'
														title={
															mayus
																? 'Permitir minúsculas'
																: 'Transformar a mayúsculas'
														}
														arrow
													>
														<ToggleButtonGroup
															data-testid='nombreInputToggleButtonGroup'
															size='small'
															color='primary'
															value={mayus}
															exclusive
															onChange={toggleConvertirMayusculas}
															sx={{
																justifyConten: 'center',
																alignItems: 'center',
															}}
														>
															<ToggleButton
																data-testid='nombreInputToogleMayusButton'
																value='mayus'
																tabIndex={-1}
																onClick={() => {
																	if (!mayus) {
																		setFieldValue(
																			'nombre',
																			values.nombre.toUpperCase()
																		);
																	}
																}}
															>
																{mayus ? (
																	<SvgComponent
																		path={SvgIcons.UpperToLowercaseIcon.svg}
																		width='24px'
																		height='24px'
																		fill='currentColor'
																	/>
																) : (
																	<SvgComponent
																		path={SvgIcons.LowerToUppercaseIcon.svg}
																		width='24px'
																		height='24px'
																		fill='currentColor'
																	/>
																)}
															</ToggleButton>
														</ToggleButtonGroup>
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormLabel required>Código postal</FormLabel>
									<Field
										data-testid='cpInput'
										name='codigoPostal'
										as={TextField}
										value={values.codigoPostal}
										fullWidth
										placeholder='Ejemplo: 27250'
										InputProps={{
											inputProps: {
												inputMode: 'numeric',
												pattern: '\\d*',
												maxLength: 5,
											},
										}}
										onChange={(e) => {
											const inputValue = e.target.value.replace(/[^0-9]/g, '');
											e.target.value = inputValue;
											setFieldValue('codigoPostal', inputValue);
										}}
										error={errors.codigoPostal && touched.codigoPostal}
										helperText={touched.codigoPostal && errors.codigoPostal}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<FormLabel required>Régimen fiscal</FormLabel>
									<Field
										data-testid='regimenFiscalSelect'
										name='regimenFiscal'
										type='string'
										as={TextField}
										value={values.regimenFiscal}
										fullWidth
										select
										SelectProps={{
											displayEmpty: true,
										}}
										error={errors.regimenFiscal && touched.regimenFiscal}
										helperText={touched.regimenFiscal && errors.regimenFiscal}
									>
										<MenuItem value='' disabled data-testid='defaultSelectItem'>
											<div
												style={{
													color: '#000000',
													opacity: '0.4',
												}}
											>
												Selecciona...
											</div>
										</MenuItem>
										{taxRegimes.map((regime) => {
											if (values.tipoDePersona === 'F' && regime.physical) {
												return (
													<MenuItem
														key={regime.key}
														value={regime.name}
														sx={{
															whiteSpace: 'normal',
														}}
														onClick={() => {
															setFieldValue('regimenFiscalKey', regime.key);
														}}
													>
														{`${regime.key} - ${regime.name}`}
													</MenuItem>
												);
											}
											if (values.tipoDePersona === 'M' && regime.moral) {
												return (
													<MenuItem
														key={regime.key}
														value={regime.name}
														sx={{
															whiteSpace: 'normal',
														}}
														onClick={() => {
															setFieldValue('regimenFiscalKey', regime.key);
														}}
													>
														{`${regime.key} - ${regime.name}`}
													</MenuItem>
												);
											}
										})}
									</Field>
								</Grid>

								<Grid item xs={12} md={12}>
									<FormLabel required>Uso de CFDI</FormLabel>
									<Field
										name='CFDI'
										type='string'
										as={TextField}
										value={values.CFDI}
										fullWidth
										select
										SelectProps={{
											displayEmpty: true,
										}}
										error={errors.CFDI && touched.CFDI}
										helperText={touched.CFDI && errors.CFDI}
										disabled={CFDIuse.length === 0}
									>
										<MenuItem value='' disabled>
											<div
												style={{
													color: '#000000',
													opacity: '0.4',
												}}
											>
												Selecciona...
											</div>
										</MenuItem>
										{CFDIuse.map((cfdi) => {
											if (values.tipoDePersona === 'F' && cfdi.physical) {
												return (
													<MenuItem
														key={cfdi.key}
														value={cfdi.name}
														sx={{
															whiteSpace: 'normal',
														}}
														onClick={() => {
															setFieldValue('CFDIKey', cfdi.key);
														}}
													>
														{`${cfdi.key} - ${cfdi.name}`}
													</MenuItem>
												);
											}
											if (values.tipoDePersona === 'M' && cfdi.moral) {
												return (
													<MenuItem
														key={cfdi.key}
														value={cfdi.name}
														sx={{
															whiteSpace: 'normal',
														}}
														onClick={() => {
															setFieldValue('CFDIKey', cfdi.key);
														}}
													>
														{`${cfdi.key} - ${cfdi.name}`}
													</MenuItem>
												);
											}
										})}
									</Field>
								</Grid>

								<Grid item xs={12} md={12}>
									<LabelWTooltip
										data-testid='LabelWTooltip'
										required
										label='Correo electrónico'
										tooltipTitle='Ingrese el correo electrónico al cual desea recibir la factura.'
									/>

									<Field
										data-testid='emailInput'
										name='correo'
										type='email'
										as={TextField}
										value={values.correo}
										fullWidth
										placeholder='Ejemplo: direccion@correo.com'
										error={errors.correo && touched.correo}
										helperText={touched.correo && errors.correo}
									/>
								</Grid>

								<Grid item xs={12} md={12}>
									<LabelWTooltip
										required
										label='Confirma tu correo electrónico'
										tooltipTitle='Ingrese nuevamente el correo electrónico al cual desea recibir la factura.'
									/>

									<Field
										data-testid='emailInputConfirmation'
										name='correoConfirmacion'
										type='email'
										as={TextField}
										value={values.correoConfirmacion}
										fullWidth
										placeholder='Ejemplo: direccion@correo.com'
										error={
											errors.correoConfirmacion && touched.correoConfirmacion
										}
										helperText={
											touched.correoConfirmacion && errors.correoConfirmacion
										}
										onPaste={(e) => {
											e.preventDefault();
										}}
									/>
								</Grid>
							</Grid>
							<StepperButtons isValid={isValid} values={values} dirty={dirty} />
						</Form>
					)}
				</Formik>
			</Grid>
		</Grid>
	);
}
