/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.css';
import { Container, Fade, Grid, Slide, useScrollTrigger } from '@mui/material';
import parse from 'html-react-parser';
import HeaderContext from '../../Context/Header/HeaderContext';

function ResizeHeaderOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 150,
	});
	return <Fade in={!trigger}>{children}</Fade>;
}

ResizeHeaderOnScroll.propTypes = {
	window: PropTypes.func,
};

function SmallHeaderOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 150,
	});

	return <Slide in={trigger}>{children}</Slide>;
}

SmallHeaderOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

export default function Header(props) {
	const { toogleSidebar, companyName, companyLogo } = useContext(HeaderContext);

	const extractColor = () => {
		const regex = /style="color:\s*(.*?)"/;
		const match = companyName.match(regex);
		if (match !== null) {
			return match[1];
		}
		return '';
	};

	function extractTextFromHTML(html) {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = html;
		return tempElement.innerText;
	}

	return (
		<>
			{window.innerWidth > 600 ? (
				<Box sx={{ flexGrow: 1 }}>
					<AppBar
						position='fixed'
						className='appbar'
						color='backgroundColorHeader'
						data-testid='header'
					>
						<Toolbar>
							<Container maxWidth='lg'>
								<Grid
									container
									direction='row'
									justifyContent='center'
									alignItems='center'
									sx={{
										height: '56px',
									}}
								>
									<Grid
										item
										xs={1}
										sx={{ height: '100%', textAlign: 'center' }}
									>
										<img
											data-testid='image'
											src={companyLogo}
											alt={companyLogo}
											style={{
												objectFit: 'contain',
												height: '100%',
												width: '100%',
											}}
										/>
									</Grid>
									<Grid item xs={10}>
										<Typography
											data-testid='companyNameText'
											className='header__title'
											sx={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												color: extractColor(),
											}}
											title={extractTextFromHTML(companyName)}
											aria-label={extractTextFromHTML(companyName)}
										>
											{parse(
												companyName
													.replace('<p>', '')
													.replace('</p>', '')
													.replace('<div>', '')
													.replace('</div>', '')
											)}
										</Typography>
									</Grid>
									<Grid item xs={1} textAlign='end'>
										<IconButton
											size='large'
											edge='start'
											color='menuColor'
											aria-label='menu'
											onClick={toogleSidebar}
											data-testid='menuButton'
										>
											<MenuIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Container>
						</Toolbar>
					</AppBar>
					<Toolbar sx={{ minHeight: '70px !important' }} />
				</Box>
			) : (
				<ResizeHeaderOnScroll {...props}>
					<Box sx={{ flexGrow: 1 }}>
						<AppBar
							position='fixed'
							className='appbar'
							color='backgroundColorHeader'
							data-testid='headerMobile'
						>
							<Toolbar>
								<Grid
									container
									direction='row'
									justifyContent='space-between'
									alignItems='center'
									sx={{ height: '56px' }}
								>
									<Grid
										item
										xs={3}
										sx={{ height: '100%', textAlign: 'center' }}
									>
										<img
											data-testid='imageMobile'
											src={companyLogo}
											alt={companyLogo}
											style={{
												objectFit: 'contain',
												height: '100%',
												width: '100%',
											}}
										/>
									</Grid>
									<Grid item xs={2.5} textAlign='center'>
										<IconButton
											size='large'
											edge='start'
											color='menuColor'
											aria-label='menu'
											onClick={toogleSidebar}
											data-testid='menuButtonMobile'
										>
											<MenuIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<Toolbar sx={{ minHeight: '70px !important' }} />
					</Box>
				</ResizeHeaderOnScroll>
			)}
		</>
	);
}

Header.propTypes = {
	window: PropTypes.func,
};
