import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LogoNegativo from '../../Assets/img/LogoNegativo.png';

export default function PoweredBy() {
	return (
		<Box
			sx={{
				flexGrow: 1,
				backgroundColor: '#202427',
				height: 50,
				display: 'flex',

				justifyContent: 'center',
			}}
		>
			<Stack
				direction='row'
				mb='4px'
				spacing={1}
				sx={{ alignItems: 'flex-end' }}
			>
				<Typography sx={{ color: 'white.main', fontSize: 12 }}>
					Desarrollado por
				</Typography>
				<img src={LogoNegativo} height={40} alt='LogoPositivo' />
			</Stack>
		</Box>
	);
}
