import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import BillingContext from '../../Context/Billing/BillingContext';
import CaptchaValidation from '../CaptchaValidation/CaptchaValidation';
import StepperButtons from '../StepperButtons/StepperButtons';
import './ConfirmationTable.css';

export default function ConfirmationTable() {
	const { initialValues, initValues } = useContext(BillingContext);

	return (
		<Grid
			container
			spacing={2}
			className='ConfirmationTable__container '
			data-testid='ConfirmationTable'
		>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					margin: '0 auto',
				}}
			>
				<Typography
					variant='h5'
					sx={{
						padding: '8px',
					}}
				>
					Resumen
				</Typography>
				<TableContainer component={Paper} elevation={3}>
					<Table
						aria-label='Summary table'
						data-testid='ConfirmationTable_table'
					>
						<TableBody data-testid='ConfirmationTable_table_body'>
							{initValues.map((item, index) =>
								item.name !== 'acceptedPolicies' &&
								item.name !== 'correoConfirmacion' ? (
									<TableRow
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
											display: { xs: 'grid', xsm: 'table-row' },
										}}
									>
										<TableCell
											component='th'
											scope='row'
											sx={{ fontWeight: 'bold' }}
										>
											{item.label}
										</TableCell>
										{item.name === 'total' ? (
											<TableCell
												sx={{ textAlign: { xs: 'left', xsm: 'right' } }}
											>
												{item.value.toLocaleString('en-IN', {
													style: 'currency',
													currency: 'USD',
												})}
											</TableCell>
										) : (
											<TableCell
												sx={{ textAlign: { xs: 'left', xsm: 'right' } }}
											>
												{item.value}
											</TableCell>
										)}
									</TableRow>
								) : null
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<CaptchaValidation />

				<StepperButtons isValid values={initialValues} dirty />
			</Grid>
		</Grid>
	);
}
