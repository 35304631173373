const getBaseUrl = () => {
	if (process.env.NODE_ENV === 'development') {
		return {
			baseUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
		};
	}

	return {
		baseUrl: `${window.location.protocol}//${window.location.hostname}`,
	};
};

const baseUrl = getBaseUrl();

export default baseUrl;
