import React, { useState, useContext, useEffect } from 'react';
import { Alert, Grow, Paper, Box } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import './CaptchaValidation.css';
import BillingContext from '../../Context/Billing/BillingContext';

export default function CaptchaValidation() {
	const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

	const { captchaIsValid, captchaRef, recaptchaValidation } =
		useContext(BillingContext);

	return (
		<Box
			sx={{
				width: '100%',
				margin: '16px 0 0 0',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignContent: 'center',
				textAlign: 'center',
				alignItems: 'center',
			}}
		>
			{!captchaIsValid ? (
				<ReCAPTCHA
					sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
					ref={captchaRef}
					onChange={async () => {
						const token = await captchaRef.current.getValue();
						if (token) {
							recaptchaValidation(token);
						} else {
							console.warn('recaptcha reloaded...');
							await captchaRef.current.reset();
						}
					}}
					size='normal'
					data-testid='recaptcha'
				/>
			) : (
				<>
					<Grow in={captchaIsValid}>
						<Paper elevation={3} sx={{ width: { xs: '100%', sm: 'auto' } }}>
							<Alert
								severity='success'
								data-testid='alert'
								sx={{ alignItems: 'center' }}
							>
								Captcha validado correctamente!
							</Alert>
						</Paper>
					</Grow>
				</>
			)}
		</Box>
	);
}
