import React, { useReducer, useContext, useEffect } from 'react';
import BillingConfigurationContext from '../BillingConfiguration/BillingConfigurationContext';
import HeaderContext from './HeaderContext';
import HeaderReducer from './HeaderReducer';

export default function HeaderState({ children }) {
	const { subscriptionData } = useContext(BillingConfigurationContext);
	const initialState = {
		openSidebar: false,
		companyName: 'NombreEmpresa',
		description: '',
		companyLogo: '',
	};
	const [state, dispatch] = useReducer(HeaderReducer, initialState);
	const { openSidebar, companyName, description, companyLogo } = state;
	useEffect(() => {
		if (subscriptionData.header)
			dispatch({
				type: 'FILLING_DATA',
				payload: subscriptionData.header,
			});
	}, [subscriptionData]);

	const toogleSidebar = () => {
		dispatch({
			type: 'TOOGLE_SIDEBAR',
			payload: !openSidebar,
		});
	};

	return (
		<HeaderContext.Provider
			value={{
				openSidebar,
				companyName,
				description,
				companyLogo,
				toogleSidebar,
			}}
		>
			{children}
		</HeaderContext.Provider>
	);
}
