import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { createElement, useContext } from 'react';
import {
	Delete,
	Error,
	Facebook,
	Instagram,
	LinkedIn,
	Twitter,
	YouTube,
} from '@mui/icons-material';
import * as MUIcon from '@mui/icons-material';

import LogoNegativo from '../../Assets/img/LogoNegativo.png';
import PoweredBy from '../../Components/PoweredBy/PoweredBy';

export default function Error404() {
	const socialNetworks = [
		{
			id: 0,
			icon: <Facebook />,
			name: 'Facebook',
			href: 'https://www.facebook.com/Microsip',
		},
		{
			id: 1,
			icon: <YouTube />,
			name: 'YouTube',
			href: 'https://www.youtube.com/user/MICROSIP',
		},
		{
			id: 2,
			icon: <LinkedIn />,
			name: 'LinkedIn',
			href: 'https://www.linkedin.com/company/microsip/mycompany/',
		},
		{
			id: 3,
			icon: <Twitter />,
			name: 'Twitter',
			href: 'https://twitter.com/microsip',
		},
	];

	const DynamicIconButton = ({ icon, color }) => {
		const Icon = createElement(MUIcon[icon], {
			color: '#fff',
			fontSize: 'inherit',
		});

		return Icon;
	};
	return (
		<Stack direction='column'>
			<Box
				sx={{
					height: '50vh',
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Typography
					variant='h4'
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Error sx={{ fontSize: '2rem' }} />
					Error
				</Typography>
				<Typography variant='h1' color='#FF8623'>
					404
				</Typography>
				<Typography variant='h4'>Page not found</Typography>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					backgroundColor: '#202427',
					display: 'flex',
					justifyContent: 'flex-start',
					height: '50vh',
					alignItems: 'center',
					paddingTop: '16px',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ color: 'white.main', fontSize: 16 }}>
					Contáctanos
				</Typography>
				<Stack direction='row' spacing={1}>
					{socialNetworks.map(({ id, icon, href, name }) => (
						<IconButton
							key={id}
							color='white'
							aria-label={name}
							href={href}
							target='_blank'
						>
							{icon}
						</IconButton>
					))}
				</Stack>
				{/* <Stack direction='row' spacing={1} sx={{ alignItems: 'flex-end' }}>
					<PoweredBy />
				</Stack> */}
			</Box>
		</Stack>
	);
}
