/* eslint-disable react/react-in-jsx-scope */
import * as MUIcon from '@mui/icons-material';
import { createElement } from 'react';

export default function DynamicIconButton({
	iconName,
	color,
	isSvg,
	textColorFooter,
}) {
	if (isSvg) {
		return (
			<svg
				data-testid='dynamic-icon-button'
				fill={textColorFooter}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 24 24'
				width='100%'
				height='100%'
			>
				<path d={iconName} />
			</svg>
		);
	}
	return createElement(MUIcon[iconName], {
		color,
		fontSize: 'inherit',
		'data-testid': 'dynamic-icon-button',
	});
}
