import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function Loader() {
	const [index, setIndex] = useState(0);
	const [intervalId, setIntervalId] = useState(null);
	const texts = [
		'Bienvenido...',
		'Espera un momento...',
		'Trabajando en ello...',
		'Casi listo...',
	];

	useEffect(() => {
		clearInterval(intervalId);
		const newIntervalId = setInterval(() => {
			setIndex((prevIndex) =>
				prevIndex === texts.length - 1 ? 0 : prevIndex + 1
			);
		}, 4000);
		setIntervalId(newIntervalId);
		return () => {
			clearInterval(newIntervalId);
		};
	}, [index]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<Typography variant='h5' sx={{ textAlign: 'center' }}>
				{texts[index]}
			</Typography>
			<CircularProgress sx={{ margin: '24px' }} size={50} />
		</Box>
	);
}
