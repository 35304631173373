import { HelpOutline } from '@mui/icons-material';
import { Box, FormLabel, Tooltip } from '@mui/material';
import React from 'react';

export default function LabelWTooltip({
	required,
	label,
	tooltipTitle,
	tooltipContent,
}) {
	return (
		<Box
			data-testid='LabelWTooltip'
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<FormLabel required={required} data-testid='LabelWTooltip_label'>
				{label}
			</FormLabel>
			<Tooltip
				data-testid='LabelWTooltip_tooltip'
				title={
					tooltipContent ? (
						<ul style={{ paddingLeft: 24 }}>
							{tooltipContent.map((item) => (
								<li key={item.id}>{item.value}</li>
							))}
						</ul>
					) : (
						tooltipTitle
					)
				}
				arrow
				placement={window.screen.width < 1175 ? 'top' : 'right'}
			>
				<HelpOutline
					color='secondary.main'
					fontSize='small'
					data-testid='iconTooltip'
				/>
			</Tooltip>
		</Box>
	);
}
