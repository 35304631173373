import React, { useReducer, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	HEADER_NODE_ID,
	DEFAULT_NODE_ID,
	APIS,
	APIEndpoints,
} from '../../Utils/Constants';
import BillingConfigurationReducer from './BillingConfigurationReducer';
import BillingConfigurationContext from './BillingConfigurationContext';

export default function BillingConfigurationState({ children }) {
	const location = useLocation();

	const { billingDomains: BillingDomainsAPI } = APIEndpoints.billingDomains;
	const { billingConfigurations: BillingConfigurationsAPI } =
		APIEndpoints.billingConfigurations;
	const { billingNodes: BillingNodesAPI } = APIEndpoints.billingNodes;

	const initialState = {
		subscriptionId: '',
		data: '',
		subscriptionData: '',
		loadingData: true,
		defaultFaqs: '',
		defaultPrivacyNotice: '',
		nodes: '',
		preview: new URLSearchParams(location.search).get('q'),
		backdropController: false,
	};

	const [state, dispatch] = useReducer(
		BillingConfigurationReducer,
		initialState
	);
	const {
		subscriptionId,
		data,
		subscriptionData,
		loadingData,
		defaultFaqs,
		defaultPrivacyNotice,

		nodes,
		preview,
		backdropController,
	} = state;

	const navigate = useNavigate();

	const isLoadingData = (isLoading) => {
		dispatch({
			type: 'TOOGLE_LOADING_DATA',
			payload: isLoading,
		});
	};

	const toogleBackdrop = (value) => {
		dispatch({
			type: 'TOOGLE_BACKDROP',
			payload: value,
		});
	};

	const getDefaultData = async () => {
		await API.get(APIS.BillingAPI, `/billing-frequent-questions`, {
			[HEADER_NODE_ID]: DEFAULT_NODE_ID,
		}).then((res) => {
			dispatch({
				type: 'SET_DEFAULT_FAQS',
				payload: res,
			});
		});
		await API.get(APIS.BillingAPI, `/billing-notice-privacy`, {
			[HEADER_NODE_ID]: DEFAULT_NODE_ID,
		}).then((res) => {
			dispatch({
				type: 'SET_DEFAULT_PRIVACY_NOTICE',
				payload: res,
			});
		});
	};

	useEffect(() => {
		if (
			!defaultFaqs &&
			!defaultPrivacyNotice
			// && !taxRegimes && !CFDIuse
		) {
			getDefaultData();
		}
		if (subscriptionData && defaultFaqs && defaultPrivacyNotice) {
			isLoadingData(false);
		}
	}, [subscriptionData, defaultFaqs, defaultPrivacyNotice]);

	const getBillingConfiguration = async (id) => {
		try {
			const response = await API.get(
				APIS.BillingAPI,
				`${BillingConfigurationsAPI.root}${BillingConfigurationsAPI.find}?subscriptionId=${id}`,
				{
					[HEADER_NODE_ID]: DEFAULT_NODE_ID,
				}
			).then((res) => {
				dispatch({
					type: 'GET_DATA_FROM_SUBSCRIPTION_ID',
					payload: res,
				});
			});

			return response;
		} catch (e) {
			if (e?.response?.status === 404) {
				isLoadingData(false);
				return undefined;
			}
			throw e;
		}
	};

	const getNodesBySubscriptionId = async (id) => {
		toogleBackdrop(true);

		try {
			const response = await API.get(
				APIS.BillingAPI,
				`${BillingNodesAPI.root}${BillingNodesAPI.list}?subscriptionId=${id}`,
				{
					[HEADER_NODE_ID]: DEFAULT_NODE_ID,
				}
			).then((res) => {
				dispatch({
					type: 'GET_NODES_BY_SUBSCRIPTION_ID',
					payload: res,
				});
				toogleBackdrop(false);
			});

			return response;
		} catch (e) {
			if (e?.response?.status === 404) {
				isLoadingData(false);
				return undefined;
			}
			throw e;
		}
	};

	const findBillingDomainByActualDomain = async (actualDomain) => {
		isLoadingData(true);
		if (preview) {
			await getBillingConfiguration(preview);
			await getNodesBySubscriptionId(preview);
		} else {
			try {
				const response = await API.get(
					APIS.BillingAPI,
					`${BillingDomainsAPI.root}${BillingDomainsAPI.find}?actualDomain=${actualDomain}`,
					{
						[HEADER_NODE_ID]: DEFAULT_NODE_ID,
					}
				).then(async (res) => {
					dispatch({
						type: 'GET_DATA_FROM_ACTUAL_DOMAIN',
						payload: res,
					});
					if (res.subscription.suspended) {
						navigate('/maintenance');
						await getBillingConfiguration(res.subscriptionId);
					} else {
						navigate('/');
						await getBillingConfiguration(res.subscriptionId);
						await getNodesBySubscriptionId(res.subscriptionId);
					}
				});

				return response;
			} catch (e) {
				if (e?.response?.status === 404) {
					isLoadingData(true);
					setTimeout(() => {
						isLoadingData(false);
						navigate('/404');
					}, 2000);
				}
			}
		}
	};

	const resetValues = () => {
		dispatch({
			type: 'RESET_VALUES',
			payload: undefined,
		});
	};
	return (
		<BillingConfigurationContext.Provider
			value={{
				subscriptionId,
				data,
				subscriptionData,
				loadingData,
				defaultFaqs,
				defaultPrivacyNotice,
				findBillingDomainByActualDomain,
				isLoadingData,
				resetValues,
				nodes,
				preview,
				backdropController,
				toogleBackdrop,
				getNodesBySubscriptionId,
			}}
		>
			{children}
		</BillingConfigurationContext.Provider>
	);
}
