// HEADER CONTEXT
export const TOOGLE_SIDEBAR = 'TOOGLE_SIDEBAR';
export const FILLING_DATA = 'FILLING_DATA';

// BILLING CONTEXT
export const NEXT_STEP = 'NEXT_STEP';
export const ADD_VALUES = 'ADD_VALUES';
export const SET_VALUES = 'SET_VALUES';
export const BACK_STEP = 'BACK_STEP';
export const RESET_BILLING_FORM = 'RESET_BILLING_FORM';
export const ON_CHANGE_CAPTCHA_VALIDATION = 'ON_CHANGE_CAPTCHA_VALIDATION';
export const TOGGLE_PRIVACY_POLICY_MODAL = 'TOGGLE_PRIVACY_POLICY_MODAL';
export const FILLING_NODES = 'FILLING_NODES';
export const API_TOKEN_JSON_BILLING = 'API_TOKEN_JSON_BILLING';
export const TOGGLE_SUBMITTING_DATA = 'TOGGLE_SUBMITTING_DATA';
export const TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION =
	'TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION';

// FAQs CONTEXT
export const HANDLE_CHANGE_ACCORDION = 'HANDLE_CHANGE_ACCORDION';

// BillingConfiguration CONTEXT
export const GET_DATA_FROM_ACTUAL_DOMAIN = 'GET_DATA_FROM_ACTUAL_DOMAIN';
export const GET_DATA_FROM_SUBSCRIPTION_ID = 'GET_DATA_FROM_SUBSCRIPTION_ID';
export const TOOGLE_LOADING_DATA = 'TOOGLE_LOADING_DATA';
export const RESET_VALUES = 'RESET_VALUES';
export const SET_DEFAULT_FAQS = 'SET_DEFAULT_FAQS';
export const SET_DEFAULT_PRIVACY_NOTICE = 'SET_DEFAULT_PRIVACY_NOTICE';
export const SET_DEFAULT_TAX_REGIMES = 'SET_DEFAULT_TAX_REGIMES';
export const SET_DEFAULT_CFDI_USE = 'SET_DEFAULT_CFDI_USE';
export const GET_NODES_BY_SUBSCRIPTION_ID = 'GET_NODES_BY_SUBSCRIPTION_ID';
