// import { OptionsProps } from 'components/form/types';

export const year = new Date().getFullYear();

export const copyrightComment = `

=========================================================
* Microsip Billing Platform as a Service
=========================================================

                                      ;;
                                  ; ;
                              ; ;;
        .;;;;;;:          :;;;;;;;
      ;;;;;;;;;        ;;;;;;;;
    ;;;;;;;;;;;;;;; ;;;;;;;;;
  ;;;;;;;;;;;;;;;;;;;;;;;;;
 ;;;;;;;;;;;;;;;;;;;;;;;;
;;;;; ;;;;;;;;;;;;;;;;;       CCCCCCCCCCCCCCCCCCC    CCCC  CCCCCCCCC CCCCCCCCL CCCCCCCCCCC   CCCCCCCCCCC   CCCC   CCCCCCCCCC.
;;;, ;;;;;;;;;;;;;;;;        CCCCC   CCCCC   LCCCC  CCCC  CCCC      CCCCC     CCCCC   CCCC  CCCCC         CCCCC  CCCC    CCCC
    ;;;;;;;;;;;;;;;         fCCCC    CCCC    CCCCC CCCCC CCCC       CCCC      CCCC    CCCC  CCCCCCCC      CCCC  CCCCC   CCCCi
     ;;;;;;;;;;;;;          CCCCC   CCCCC    CCCC  CCCC  CCCC      CCCCC     CCCCL   CCCC1     CCCCCCCC  CCCC   CCCC    CCCC
      ;;;;;;;;;;           CCCCC    CCCC    CCCC, CCCC  CCCC       CCCC      CCCC    CCCC         CCCCC  CCCC  CCCC    CCCC
      ;;;;;;;;;            CCCC    CCCCC   CCCCC  CCCC  CCCCCCCCC CCCCL      CCCCCCCCCCC   CCCCCCCCCCC  CCCC  ,CCCCCCCCCCC
       ;;;;;;;                                                                                                CCCC
           ;;                                                                                                CCCC.

* Copyright ${year} Aplicaciones y Proyectos Computacionales, S. A. de C. V. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential

* Coded by paco <paco@technogi.com.mx>

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

  `;

export const APIS = {
	SiCSAPI: 'SiCSAPI',
	BillingAPI: 'BillingAPI',
};

export const APIEndpoints = {
	subscriptions: {
		subscriptions: {
			root: '/subscripciones',
		},
	},
	billingConfigurations: {
		billingConfigurations: {
			root: '/billing-configuration',
			find: '/find',
		},
	},
	billingDomains: {
		billingDomains: {
			root: '/domain-management',
			find: '/find',
		},
	},
	recaptcha: {
		recaptcha: {
			root: '/api/recaptcha',
			v2: '/v2',
			v3: '/v3',
		},
	},
	billingNodes: {
		billingNodes: {
			root: '/billing-node',
			list: '/list',
		},
	},
	billingData: {
		billingData: {
			root: '/billing-data',
			api: '/api',
			queue: '/queue',
			find: '/find',
			recaptcha: '/billing-recaptcha',
		},
	},
};

export const DEFAULT_HEAD_TITLE = 'Microsip Billing Platform as a Service';
export const HEADER_NODE_ID = 'X-ID-NODO';
export const HEADER_SUBSCRIPTION_ID = 'X-ID-SUBSCRIPCION';
export const HEADER_USERNAME = 'X-USERNAME';
export const DEFAULT_NODE_ID = 'microsip-billing-portal';

export const MAX_FILE_SIZE = 5242880;

export const defaultValueSelectAnOption = '-1';
export const defaultLabelSelectAnOption = 'Selecciona una opción';

export const RecaptchaActionTypes = {
	Billing: 'Billing',
};

export const SvgIcons = {
	UpperToLowercaseIcon: {
		svg: 'M382.95 350.3c-13.03 0-24.49-2.12-34.47-6.42-9.91-4.3-17.76-10.85-23.43-19.57-5.67-8.79-8.47-19.94-8.47-33.46 0-11.16 1.87-20.69 5.67-28.67 3.86-7.91 9.16-14.39 15.95-19.44 6.86-5.05 14.77-8.85 23.87-11.53 9.03-2.68 18.88-4.36 29.41-5.17 11.41-.88 20.56-2 27.54-3.24 6.92-1.31 11.97-3.12 15.09-5.49 3.05-2.3 4.61-5.42 4.61-9.34v-.5c0-5.36-2.06-9.41-6.11-12.28-4.05-2.8-9.28-4.24-15.64-4.24-7.04 0-12.71 1.56-17.08 4.62-4.36 3.05-7.04 7.72-7.91 14.02h-67.43c.88-12.4 4.8-23.87 11.84-34.34 7.05-10.53 17.33-18.88 30.91-25.24 13.53-6.35 30.41-9.47 50.73-9.47 14.58 0 27.66 1.68 39.26 5.11 11.65 3.37 21.49 8.1 29.66 14.21 8.1 6.05 14.33 13.15 18.63 21.25 4.3 8.1 6.42 16.89 6.42 26.36v139.65h-68.48v-28.67h-1.62c-4.06 7.6-8.98 13.77-14.78 18.45-5.79 4.67-12.4 8.1-19.81 10.22-7.35 2.12-15.52 3.18-24.36 3.18zm-58.23-245.15 20.81-25.92c-14.18-6.15-31.94-11.19-50.61-9.75-21.34 1.64-44.09 11.87-64.36 38.9a5.585 5.585 0 0 1-7.82 1.12 5.59 5.59 0 0 1-2.08-5.75c2.22-10.88 5.68-20.46 10.08-28.84 13.12-24.98 34.61-39.49 57.76-46.72 22.82-7.12 47.31-7.13 66.86-3.16l.21.05c6.72 1.38 12.88 3.23 18.21 5.43l9.69-26.81c1.03-2.9 4.24-4.41 7.14-3.37 1.62.58 2.81 1.83 3.36 3.33l31.67 85.44c1.06 2.9-.43 6.12-3.33 7.19l-1.11.28-91.17 17.7c-3.03.58-5.97-1.41-6.54-4.44-.34-1.73.17-3.43 1.23-4.68zM79.7 347.12H0L89.8 75.17h100.88l89.74 271.95h-79.64l-15.72-52.04H95.4l-15.7 52.04zm88.67-107.31-27.1-89.74h-2.12l-27.08 89.74h56.3zm254.21 60.82c4.92-2.31 8.91-5.61 12.02-9.97 3.12-4.3 4.68-9.6 4.68-15.77v-17.01c-9.4 3.8-19.23 5.92-29.23 7.42-11.87 1.96-24.43 7.32-24.43 21.25 0 19.23 23.57 20.35 36.96 14.08z',
	},
	LowerToUppercaseIcon: {
		svg: 'M314.12 356.1h-78.57l88.53-268.09h99.46L512 356.1h-78.51l-15.5-51.29H329.6l-15.48 51.29zM192.77 106.76l19-26.44c-14.3-5.32-32.06-9.35-50.33-6.95-20.92 2.75-42.78 14.03-61.31 41.73-1.69 2.53-5.11 3.2-7.64 1.51a5.494 5.494 0 0 1-2.39-5.38c1.59-10.85 4.5-20.52 8.43-29.09 11.6-25.29 31.98-40.71 54.39-49.05 22.12-8.24 46.22-9.55 65.64-6.68 6.74 1 12.98 2.52 18.42 4.43l8.11-26.9a5.524 5.524 0 0 1 6.87-3.71c1.76.52 3.06 1.84 3.63 3.45l35.53 82.09c1.2 2.79-.08 6.04-2.87 7.24l-.85.29-89.02 22.32a5.527 5.527 0 0 1-6.7-4.01c-.44-1.77.02-3.54 1.09-4.85zM65.43 359.24c-12.84 0-24.14-2.09-33.97-6.33-9.77-4.24-17.51-10.69-23.1-19.29C2.76 324.96 0 313.96 0 300.63c0-11 1.84-20.39 5.59-28.26 3.81-7.8 9.03-14.19 15.73-19.17 6.76-4.97 14.56-8.72 23.53-11.36 8.9-2.65 18.61-4.3 28.99-5.1 11.25-.86 20.27-1.97 27.16-3.2 6.82-1.29 11.79-3.07 14.86-5.4 3.01-2.28 4.55-5.35 4.55-9.22v-.49c0-5.28-2.03-9.27-6.02-12.1-3.99-2.76-9.15-4.18-15.42-4.18-6.94 0-12.53 1.54-16.83 4.55-4.3 3.01-6.95 7.61-7.8 13.82H7.86c.86-12.22 4.74-23.53 11.67-33.85 6.95-10.38 17.08-18.61 30.48-24.88 13.33-6.27 29.98-9.34 50-9.34 14.38 0 27.28 1.66 38.71 5.04 11.49 3.32 21.19 7.99 29.24 14.01 7.99 5.96 14.13 12.96 18.37 20.95 4.24 7.98 6.33 16.64 6.33 25.98V356.1h-67.52v-28.25h-1.6c-3.99 7.49-8.84 13.57-14.56 18.18-5.71 4.61-12.22 7.98-19.53 10.07-7.25 2.09-15.3 3.14-24.02 3.14zm50.93-58.8c3.07-4.23 4.6-9.46 4.6-15.54v-16.77c-9.35 3.79-18.84 5.82-28.81 7.31-11.7 1.93-24.08 7.22-24.08 20.95 0 5.59 1.97 9.89 5.9 12.84 12.5 9.38 33.63 3.46 42.39-8.79zm285.18-50.12-26.72-88.47h-2.09l-26.69 88.47h55.5z',
	},
};

export const UrlEndpoints = {
	dev: 'https://api-facturacion.dev.sics.microsipnube.com',
	prod: 'https://api-facturacion.sics.facturadorenlinea.com',
};
