import { v4 as uuid } from 'uuid';
import {
	ADD_VALUES,
	BACK_STEP,
	NEXT_STEP,
	RESET_BILLING_FORM,
	SET_VALUES,
	ON_CHANGE_CAPTCHA_VALIDATION,
	TOGGLE_PRIVACY_POLICY_MODAL,
	FILLING_DATA,
	FILLING_NODES,
	API_TOKEN_JSON_BILLING,
	TOGGLE_SUBMITTING_DATA,
	TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION,
} from '../types';

export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case ADD_VALUES:
			return {
				...state,
				initialValues: payload,
			};
		case SET_VALUES:
			return {
				...state,
				initValues: state.initValues.map((item, index) => {
					if (item.name === Object.entries(payload)[index][0]);
					// eslint-disable-next-line prefer-destructuring, no-param-reassign
					item.value = Object.entries(payload)[index][1];
					return item;
				}),
			};
		case NEXT_STEP:
			return {
				...state,
				activeStep: payload + 1,
			};
		case BACK_STEP:
			return {
				...state,
				activeStep: payload - 1,
			};

		case RESET_BILLING_FORM:
			return {
				...state,
				activeStep: payload,
				initialValues: {
					folio: '',
					nombreSucursal: '',
					total: '',
					acceptedPolicies: false,
					tipoDePersonaText: 'Física',
					rfc: '',
					nombre: '',
					codigoPostal: '',
					regimenFiscal: '',
					CFDI: '',
					correo: '',
					correoConfirmacion: '',
					invoiceId: uuid(),
					apiToken: '',
					CFDIKey: '',
					regimenFiscalKey: '',
					sucursalId: '',
					tipoDePersona: 'F',
					billerVersion: process.env.REACT_APP_BILLER_VERSION,
					processType: 'F',
				},
				captchaToken: null,
				captchaIsValid: false,
				jsonBilling: {},
				dataIsReady: false,
				invoiceStatus: false,
				taxRegimes: [],
				CFDIuse: [],
				backdropController: false,
			};

		case ON_CHANGE_CAPTCHA_VALIDATION:
			return {
				...state,
				captchaIsValid: payload,
			};
		case TOGGLE_PRIVACY_POLICY_MODAL:
			return {
				...state,
				PrivacyPolicyModalIsOpen: payload,
			};

		case FILLING_DATA:
			return {
				...state,
				notices: payload.billingInstructions.notices,
				noticeOfPrivacy: payload.noticeOfPrivacy,
				instructionsImage: `${process.env.REACT_APP_ASSETS_URL}/${payload.billingInstructions.instructionsImage.level}/${payload.billingInstructions.instructionsImage.key}`,
			};
		case FILLING_NODES:
			return {
				...state,
				branchOffices: payload,
			};

		case API_TOKEN_JSON_BILLING:
			return {
				...state,
				initialValues: {
					...state.initialValues,
					apiToken: payload,
				},
			};

		case TOGGLE_SUBMITTING_DATA:
			return {
				...state,
				submittingData: payload,
			};
		case 'TOOGLE_INVOICE':
			return {
				...state,
				invoiceStatus: payload,
			};

		case 'SET_DEFAULT_TAX_REGIMES':
			return {
				...state,
				taxRegimes: payload,
			};
		case 'SET_DEFAULT_CFDI_USE':
			return {
				...state,
				CFDIuse: payload,
			};

		case TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION:
			return {
				...state,
				isLoadingRecaptchaValidation: payload,
			};

		default:
			return state;
	}
};
