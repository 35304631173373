import { TOOGLE_SIDEBAR, FILLING_DATA } from '../types';

export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case TOOGLE_SIDEBAR:
			return {
				...state,
				openSidebar: payload,
			};
		case FILLING_DATA:
			return {
				...state,
				companyName: payload.name,
				description: payload.description,
				companyLogo: `${process.env.REACT_APP_ASSETS_URL}/${payload.companyLogo.level}/${payload.companyLogo.key}`,
			};

		default:
			return state;
	}
};
