import React, { useReducer, useContext, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import CustomizationContext from './CustomizationContext';
import CustomizationReducer from './CustomizationReducer';
import Logo from '../../Assets/img/genericLogo.png';
import circleLogo1 from '../../Assets/img/genericCircleLogo.png';
import LogoC from '../../Assets/img/logoMspSm2.jpg';
import Logo3 from '../../Assets/img/logo3.png';
import Logo3w from '../../Assets/img/logo3w.png';
import BillingConfigurationContext from '../BillingConfiguration/BillingConfigurationContext';

export default function CustomizationState({ children }) {
	const { subscriptionData } = useContext(BillingConfigurationContext);

	const initialState = {
		// primaryColor: '#2D4B73',
		primaryColor: '',
		// secondaryColor: '#C08D31',
		secondaryColor: '#C4C4C4',
		// thirdColor: '#99B5C0',
		thirdColor: '#C4C4C4',
		white: '#FFFFFF',
		logo: Logo3,
		circleLogo: Logo3w,
		address: '',
		email: '',
		phone: '',
		socialMedia: [],
		companyLogoFooter: '',
		backgroundColorHeader: '',
		backgroundColorFooter: '',
		textColorFooter: '',
		menuColor: '',
	};

	const [state, dispatch] = useReducer(CustomizationReducer, initialState);
	const {
		primaryColor,
		secondaryColor,
		thirdColor,
		white,
		logo,
		circleLogo,
		address,
		email,
		phone,
		socialMedia,
		companyLogoFooter,
		backgroundColorHeader,
		backgroundColorFooter,
		textColorFooter,
		menuColor,
	} = state;

	useEffect(() => {
		if (subscriptionData.footer) {
			dispatch({
				type: 'FILLING_DATA',
				payload: subscriptionData,
			});
		}
	}, [subscriptionData]);

	return (
		<CustomizationContext.Provider
			value={{
				primaryColor,
				secondaryColor,
				thirdColor,
				menuColor,
				white,
				logo,
				circleLogo,
				address,
				email,
				phone,
				socialMedia,
				companyLogoFooter,
				backgroundColorHeader,
				backgroundColorFooter,
				textColorFooter,
			}}
		>
			{children}
		</CustomizationContext.Provider>
	);
}
