import React, { useContext } from 'react';
import { Close, ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Slide,
	Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import { options } from '../../Utils/OptionToParse';

import BillingContext from '../../Context/Billing/BillingContext';
import './PrivacyPolicyModal.css';
import BillingConfigurationContext from '../../Context/BillingConfiguration/BillingConfigurationContext';

const Transition = React.forwardRef((props, ref) => (
	<Slide direction='up' ref={ref} {...props} />
));

export default function PrivacyPolicyModal() {
	const {
		PrivacyPolicyModalIsOpen,
		TogglePrivacyPolicyModal,
		noticeOfPrivacy,
	} = useContext(BillingContext);

	const { defaultPrivacyNotice } = useContext(BillingConfigurationContext);
	return (
		<div data-testid='ppModal'>
			<Dialog
				open={PrivacyPolicyModalIsOpen}
				onClose={TogglePrivacyPolicyModal}
				scroll='paper'
				aria-labelledby='scroll-dialog-title-privacy-policy'
				aria-describedby='scroll-dialog-privacy-policy'
				TransitionComponent={Transition}
				data-testid='ppModal_dialog'
			>
				<DialogTitle
					data-testid='ppModal_dialog_title'
					id='scroll-dialog-title-privacy-policy'
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					Política de privacidad
					<IconButton
						data-testid='ppModal_dialog_closeButton'
						aria-label='close-privacy-policy'
						onClick={TogglePrivacyPolicyModal}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText
						id='scroll-dialog-privacy-policy'
						tabIndex={-1}
						component='span'
						data-testid='ppModal_dialog_content'
					>
						<div data-testid='ppModal_dialog_content_text'>
							{noticeOfPrivacy && parse(noticeOfPrivacy, options)}
						</div>
						<Accordion data-testid='ppModal_accordion'>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls='Politica_de_privacidad_Microsip'
								id='Politica_de_privacidad_Microsip'
							>
								<Typography>Política de privacidad Microsip</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{parse(defaultPrivacyNotice?.description, options)}
							</AccordionDetails>
						</Accordion>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={TogglePrivacyPolicyModal}>Cerrar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
