import React, { useContext } from 'react';
import { scroller } from 'react-scroll';
import styled from '@emotion/styled';
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	IconButton,
	useMediaQuery,
	useTheme,
	Drawer,
} from '@mui/material';
import {
	Close,
	QuestionAnswer,
	ContactPage,
	Receipt,
	Home,
} from '@mui/icons-material';
import HeaderContext from '../../Context/Header/HeaderContext';

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-start',
}));

export default function SideBar() {
	const { openSidebar, toogleSidebar } = useContext(HeaderContext);

	const menuList = [
		{
			id: 0,
			label: 'Inicio',
			icon: <Home />,
			scrollTo: 'home',
		},
		{
			id: 1,
			label: 'Facturar',
			icon: <Receipt />,
			scrollTo: 'billing',
		},
		{
			id: 2,
			label: 'Preguntas frecuentes',
			icon: <QuestionAnswer />,
			scrollTo: 'FAQs',
		},
		{
			id: 3,
			label: 'Contacto',
			icon: <ContactPage />,
			scrollTo: 'contact',
		},
	];

	const list = (windowWidth) => (
		<Box sx={{ width: windowWidth ? 350 : '100%' }}>
			<List>
				{menuList.map((item) => (
					<ListItem key={item.id} disablePadding onClick={toogleSidebar}>
						<ListItemButton
							onClick={() =>
								scroller.scrollTo(item.scrollTo, {
									duration: 500,
									delay: 0,
									smooth: 'easeInOutQuart',
									offset: -100,
								})
							}
						>
							<ListItemIcon sx={{ color: 'primary.main' }}>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={item.label}
								primaryTypographyProps={{ color: 'primary' }}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);
	const theme = useTheme();
	const windowWidth = useMediaQuery(theme.breakpoints.up('sm'));
	return (
		<Drawer
			anchor={windowWidth ? 'right' : 'top'}
			open={openSidebar}
			onClose={toogleSidebar}
			sx={{ paddingBottom: '16px !important' }}
			data-testid='sidebar'
		>
			<DrawerHeader>
				<IconButton onClick={toogleSidebar} data-testid='sidebar_closeButton'>
					<Close />
				</IconButton>
			</DrawerHeader>
			<Divider />
			{list(windowWidth)}
		</Drawer>
	);
}
