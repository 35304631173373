import React, { useContext, useReducer, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	APIEndpoints,
	APIS,
	DEFAULT_NODE_ID,
	HEADER_NODE_ID,
	HEADER_USERNAME,
} from '../../Utils/Constants';
import BillingConfigurationContext from '../BillingConfiguration/BillingConfigurationContext';
import BillingContext from './BillingContext';
import BillingReducer from './BillingReducer';

export default function BillingState({ children }) {
	const { subscriptionData, nodes, subscriptionId, toogleBackdrop } =
		useContext(BillingConfigurationContext);
	const captchaRef = useRef(null);

	const { billingData: BillingDataAPI } = APIEndpoints.billingData;
	const location = useLocation();

	const initialState = {
		initialValues: {
			folio: new URLSearchParams(location.search).get('folio') || '',
			nombreSucursal: '',
			total: new URLSearchParams(location.search).get('total') || '',
			acceptedPolicies: false,
			tipoDePersonaText: 'Física',
			rfc: '',
			nombre: '',
			codigoPostal: '',
			regimenFiscal: '',
			CFDI: '',
			correo: '',
			correoConfirmacion: '',
			apiToken: '',
			CFDIKey: '',
			regimenFiscalKey: '',
			sucursalId: new URLSearchParams(location.search).get('sucursalId') || '',
			tipoDePersona: 'F',
			invoiceId: uuid(),
			billerVersion: process.env.REACT_APP_BILLER_VERSION,
			processType: 'F',
		},

		initValues: [
			{
				name: 'folio',
				label: 'Folio',
				value: '',
			},
			{
				name: 'nombreSucursal',
				label: 'Sucursal',
				value: '',
			},
			{
				name: 'total',
				label: 'Total',
				value: '',
			},
			{
				name: 'acceptedPolicies',
				label: 'Politicas aceptadas',
				value: '',
			},
			{
				name: 'tipoDePersonaText',
				label: 'Tipo de persona',
				value: '',
			},
			{
				name: 'rfc',
				label: 'RFC',
				value: '',
			},
			{
				name: 'nombre',
				label: 'Nombre',
				value: '',
			},
			{
				name: 'codigoPostal',
				label: 'Código postal',
				value: '',
			},
			{
				name: 'regimenFiscal',
				label: 'Régimen fiscal',
				value: '',
			},
			{
				name: 'CFDI',
				label: 'Uso de CFDI',
				value: '',
			},
			{
				name: 'correo',
				label: 'Correo',
				value: '',
			},
			{
				name: 'correoConfirmacion',
				label: 'Confirmación de correo',
				value: '',
			},
		],
		activeStep: 0,
		captchaIsValid: false,
		PrivacyPolicyModalIsOpen: false,
		notices: '',
		noticeOfPrivacy: '',
		instructionsImage: '',
		branchOffices: [],
		submittingData: false,
		invoiceStatus: false,
		taxRegimes: [],
		CFDIuse: [],
		isLoadingRecaptchaValidation: false,
	};

	const [state, dispatch] = useReducer(BillingReducer, initialState);
	const {
		initialValues,
		activeStep,
		ticketDataButton,
		initValues,
		captchaIsValid,
		PrivacyPolicyModalIsOpen,
		notices,
		noticeOfPrivacy,
		instructionsImage,
		branchOffices,
		submittingData,
		invoiceStatus,
		taxRegimes,
		CFDIuse,
		isLoadingRecaptchaValidation,
	} = state;

	useEffect(() => {
		if (subscriptionData) {
			dispatch({
				type: 'FILLING_DATA',
				payload: subscriptionData,
			});
		}
		if (nodes) {
			dispatch({
				type: 'FILLING_NODES',
				payload: nodes,
			});
		}
	}, [subscriptionData, nodes]);

	const handleNext = (values) => {
		dispatch({
			type: 'ADD_VALUES',
			payload: values,
		});

		dispatch({
			type: 'SET_VALUES',
			payload: values,
		});
		dispatch({
			type: 'NEXT_STEP',
			payload: activeStep,
		});
	};

	const handleBack = () => {
		dispatch({
			type: 'BACK_STEP',
			payload: activeStep,
		});
	};

	const handleReset = () => {
		dispatch({
			type: 'RESET_BILLING_FORM',
			payload: 0,
		});
	};

	const onChangeCaptchaValidation = (value) => {
		dispatch({
			type: 'ON_CHANGE_CAPTCHA_VALIDATION',
			payload: value,
		});
	};

	const TogglePrivacyPolicyModal = () => {
		dispatch({
			type: 'TOGGLE_PRIVACY_POLICY_MODAL',
			payload: !PrivacyPolicyModalIsOpen,
		});
	};
	const ToggleSubmittingData = (value) => {
		dispatch({
			type: 'TOGGLE_SUBMITTING_DATA',
			payload: value,
		});
	};

	const resetCfdiUses = () => {
		dispatch({
			type: 'SET_DEFAULT_CFDI_USE',
			payload: [],
		});
	};

	const getTaxRegime = async (value) => {
		toogleBackdrop(true);
		await API.get(
			APIS.BillingAPI,
			`/billing-tax-regime/find?personType=${value}`,
			{
				[HEADER_NODE_ID]: DEFAULT_NODE_ID,
			}
		).then((res) => {
			dispatch({
				type: 'SET_DEFAULT_TAX_REGIMES',
				payload: res,
			});
			toogleBackdrop(false);
		});
	};
	const getCfdiUses = async (value) => {
		toogleBackdrop(true);
		await API.get(
			APIS.BillingAPI,
			`/billing-cfdi-use/find?taxRegime=${value}`,
			{
				[HEADER_NODE_ID]: DEFAULT_NODE_ID,
			}
		).then((res) => {
			dispatch({
				type: 'SET_DEFAULT_CFDI_USE',
				payload: res,
			});
			toogleBackdrop(false);
		});
	};

	const getInvoiceById = async (invoiceId) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await API.get(
				APIS.BillingAPI,
				`${BillingDataAPI.root}${BillingDataAPI.find}?invoiceId=${invoiceId}`,
				{
					[HEADER_NODE_ID]: DEFAULT_NODE_ID,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	const sendBillingData = async (values) => {
		ToggleSubmittingData(true);
		const {
			folio,
			total,
			tipoDePersona,
			rfc,
			nombre,
			codigoPostal,
			correo,
			apiToken,
			CFDIKey,
			regimenFiscalKey,
			invoiceId,
			billerVersion,
			processType,
		} = initialValues;

		const jsonToQueue = {
			folio,
			total,
			tipoDePersona,
			rfc,
			nombre,
			codigoPostal,
			correo,
			apiToken,
			CFDIKey,
			regimenFiscalKey,
			subscriptionId,
			invoiceId,
			billerVersion,
			processType,
		};

		const payloadToQueue = {
			headers: {
				[HEADER_NODE_ID]: DEFAULT_NODE_ID,
			},
			body: jsonToQueue,
		};
		dispatch({
			type: 'SET_JSON_DATA',
			payload: jsonToQueue,
		});

		try {
			const response = await API.post(
				APIS.BillingAPI,
				`${BillingDataAPI.root}${BillingDataAPI.queue}`,
				payloadToQueue
			).then((res) => {
				ToggleSubmittingData(false);
				handleNext({ ...initialValues, subscriptionId });
			});
			return response;
		} catch (e) {
			ToggleSubmittingData(false);
			if (e?.response?.status === 404) {
				toast.error('No se pudo enviar tu petición, inténtalo nuevamente.', {
					position: 'top-right',
					theme: 'colored',
				});
				return undefined;
			}
			if (e?.response?.status === 500) {
				toast.error('El servicio no esta disponible.', {
					position: 'top-right',
					theme: 'colored',
				});
				return undefined;
			}
			throw e;
		}
	};

	const toogleInvoice = (value) => {
		dispatch({
			type: 'TOOGLE_INVOICE',
			payload: value,
		});
	};

	const recaptchaValidation = async (captchaToken) => {
		dispatch({
			type: 'TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION',
			payload: true,
		});

		try {
			const response = await API.post(
				APIS.BillingAPI,
				`${BillingDataAPI.recaptcha}`,
				{
					headers: {
						[HEADER_NODE_ID]: DEFAULT_NODE_ID,
					},
					body: { captchaToken },
				}
			);

			if (response === true) {
				dispatch({
					type: 'TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION',
					payload: false,
				});
				onChangeCaptchaValidation(response);
			} else {
				captchaRef.current.reset();
				dispatch({
					type: 'TOOGLE_IS_LOADING_RECAPTCHA_VALIDATION',
					payload: false,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<BillingContext.Provider
			value={{
				initialValues,
				activeStep,
				ticketDataButton,
				initValues,
				captchaIsValid,
				PrivacyPolicyModalIsOpen,
				notices,
				noticeOfPrivacy,
				instructionsImage,
				submittingData,
				invoiceStatus,
				handleNext,
				handleBack,
				handleReset,
				onChangeCaptchaValidation,
				TogglePrivacyPolicyModal,
				branchOffices,
				sendBillingData,
				getInvoiceById,
				toogleInvoice,
				taxRegimes,
				CFDIuse,
				getTaxRegime,
				getCfdiUses,
				resetCfdiUses,
				captchaRef,
				recaptchaValidation,
				isLoadingRecaptchaValidation,
			}}
		>
			{children}
		</BillingContext.Provider>
	);
}
