import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes/index';
import './index.css';
import HeaderState from './Context/Header/HeaderState';
import CustomizationState from './Context/Customization/CustomizationState';
import BillingState from './Context/Billing/BillingState';
import FAQsState from './Context/FAQs/FAQsState';
import BillingConfigurationState from './Context/BillingConfiguration/BillingConfigurationState';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router>
			<BillingConfigurationState>
				<CustomizationState>
					<HeaderState>
						<BillingState>
							<FAQsState>
								<StyledEngineProvider injectFirst>
									<Routes />
								</StyledEngineProvider>
							</FAQsState>
						</BillingState>
					</HeaderState>
				</CustomizationState>
			</BillingConfigurationState>
		</Router>
	</React.StrictMode>
);
