import React, { useContext } from 'react';
import './Welcome.css';
import parse from 'html-react-parser';
import { options } from '../../Utils/OptionToParse';

import HeaderContext from '../../Context/Header/HeaderContext';

export default function Welcome() {
	const { description } = useContext(HeaderContext);
	return (
		<div className='welcome__container'>{parse(description, options)}</div>
	);
}
