import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BillingConfigurationContext from '../Context/BillingConfiguration/BillingConfigurationContext';
import baseUrl from '../config/index';

// Pages
import Main from '../Pages/Main/Main';
import Loader from '../Components/Loader/Loader';
import Error404 from '../Pages/Errors/Error404';
import Maintenance from '../Pages/Errors/Maintenance';
import Theme from '../Utils/Theme';

export default function index() {
	const { findBillingDomainByActualDomain, loadingData } = useContext(
		BillingConfigurationContext
	);

	useEffect(() => {
		findBillingDomainByActualDomain(baseUrl.baseUrl);
	}, []);

	return (
		<Theme>
			<Routes>
				<Route exact path='/' element={loadingData ? <Loader /> : <Main />} />
				<Route exact path='/404' element={<Error404 />} />
				<Route
					exact
					path='/maintenance'
					element={loadingData ? <Loader /> : <Maintenance />}
				/>
				<Route path='*' element={<Navigate to='/' replace />} />
			</Routes>
		</Theme>
	);
}
