import React, { useReducer, useContext, useEffect } from 'react';
import FAQsReducer from './FAQsReducer';
import FAQsContext from './FAQsContext';
import BillingConfigurationContext from '../BillingConfiguration/BillingConfigurationContext';

function FAQsState({ children }) {
	const { subscriptionData } = useContext(BillingConfigurationContext);
	const initialState = {
		expanded: false,
		frequentQuestions: '',
	};
	const [state, dispatch] = useReducer(FAQsReducer, initialState);
	const { expanded, frequentQuestions } = state;

	useEffect(() => {
		if (subscriptionData.frequentQuestions)
			dispatch({
				type: 'FILLING_DATA',
				payload: subscriptionData.frequentQuestions,
			});
	}, [subscriptionData]);

	const handleChangeAccordion = (panel) => (event, isExpanded) => {
		dispatch({
			type: 'HANDLE_CHANGE_ACCORDION',
			payload: isExpanded ? panel : false,
		});
	};

	return (
		<FAQsContext.Provider
			value={{ expanded, frequentQuestions, handleChangeAccordion }}
		>
			{children}
		</FAQsContext.Provider>
	);
}

export default FAQsState;
