import React, { useContext } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Stack, Grid } from '@mui/material';
import parse from 'html-react-parser';
import BillingContext from '../../Context/Billing/BillingContext';

const options = {
	replace: (domNode) => {
		if (domNode.attribs && domNode.attribs.class === 'remove') {
			return <></>;
		}
	},
};

export default function WarningMessage() {
	const { notices } = useContext(BillingContext);

	return (
		<>
			{notices && (
				<Stack sx={{ width: '100%', pt: '16px' }}>
					<Grid container spacing={2}>
						<Alert
							severity='warning'
							sx={{ width: '100%', mt: '16px', ml: '16px' }}
						>
							<AlertTitle>
								<strong>Consideraciones importantes</strong>
							</AlertTitle>
							{parse(notices, options)}
						</Alert>
					</Grid>
				</Stack>
			)}
		</>
	);
}
