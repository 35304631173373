/* eslint-disable no-underscore-dangle */
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormLabel,
	Grid,
	InputAdornment,
	Link,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { object, string, number } from 'yup';
import { NumericFormat } from 'react-number-format';
import './TicketData.css';
import BillingContext from '../../Context/Billing/BillingContext';
import StepperButtons from '../StepperButtons/StepperButtons';
import PrivacyPolicyModal from '../PrivacyPolicyModal/PrivacyPolicyModal';
import BillingConfigurationContext from '../../Context/BillingConfiguration/BillingConfigurationContext';

export default function TicketData() {
	const {
		initialValues,
		TogglePrivacyPolicyModal,
		instructionsImage,
		branchOffices,
	} = useContext(BillingContext);
	const { subscriptionId, getNodesBySubscriptionId } = useContext(
		BillingConfigurationContext
	);

	const setApiToken = (node, setFieldValue) => {
		setFieldValue('nombreSucursal', node.name);

		// ? Sucursal Matriz
		if (node.type === 'M') {
			setFieldValue('apiToken', node.apiToken);
		}

		// ? Sucursal Replix
		if (node.type === 'I') {
			setFieldValue('apiToken', node.apiToken);
		}

		// ? Sucursal centralizada
		if (node?.installationType === 'S') {
			if (node.type === 'M') {
				setFieldValue('apiToken', node.apiToken);
			} else if (node.type === 'S') {
				setFieldValue('apiToken', node.matriz?.apiToken);
			}
		}

		// ? Sucursal PVO
		if (node?.installationType === 'D') {
			setFieldValue('apiToken', node.apiToken);
		}

		// ? Catalogos Centralizados
		if (node?.installationType === 'C') {
			setFieldValue('apiToken', node.apiToken);
		}
	};

	useEffect(() => {
		getNodesBySubscriptionId(subscriptionId);
	}, []);

	return (
		<Grid
			container
			spacing={2}
			className='TicketData__container'
			data-testid='ticketData'
		>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography variant='h5'>Instrucciones</Typography>
				<Box sx={{ width: { md: '65%', sm: '70%', xsm: '80%' } }}>
					<img
						src={instructionsImage}
						alt={instructionsImage}
						className='TicketData__img'
					/>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography variant='h5'>Datos del ticket</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={object({
						folio: string()
							.required('Es un campo requerido.')
							.min(1, 'El folio debe contener mínimo 1 carácteres.')
							.matches(
								/^[A-Za-z][A-Za-z0-9]{1,2}\d{0,6}$/,
								'El formato del folio está incorrecto.'
							),
						sucursalId: string().required('Es un campo requerido.'),
						total: number()
							.test(
								'not-zero',
								'El valor del campo no puede ser cero',
								(value) => value !== 0
							)
							.moreThan(
								0,
								'El campo contiene numeros negativos, el valor debe ser mayor que cero.'
							)
							.typeError('Es un campo requerido.')
							.required('Es un campo requerido.'),
					})}
					validateOnMount
				>
					{({ errors, isValid, touched, values, setFieldValue }) => (
						<Box className='ticketData__form--container'>
							<div className='ticketData_form--inputGroup'>
								<FormLabel required data-testid='folioTicket'>
									Folio del ticket
								</FormLabel>

								<Field
									name='folio'
									type='string'
									as={TextField}
									value={values.folio}
									fullWidth
									placeholder='Ejemplo: AB104'
									id='ticket'
									data-testid='ticket'
									error={errors.folio && touched.folio}
									helperText={touched.folio && errors.folio}
									inputProps={{
										maxLength: '9',
									}}
									onChange={(e) => {
										setFieldValue('folio', e.target.value.toUpperCase());
									}}
								/>
							</div>
							<div className='ticketData_form--inputGroup'>
								<FormLabel required>Sucursal</FormLabel>
								<Field
									name='sucursalId'
									fullWidth
									id='sucursalId'
									data-testid='sucursalId'
									select
									as={TextField}
									value={values.sucursalId}
									SelectProps={{
										displayEmpty: true,
									}}
									error={errors.sucursalId && touched.sucursalId}
									helperText={touched.sucursalId && errors.sucursalId}
								>
									<MenuItem value='' disabled>
										<div
											style={{
												color: '#000000',
												opacity: '0.4',
											}}
										>
											Selecciona...
										</div>
									</MenuItem>
									{branchOffices?.map((node) => {
										if (!values.nombreSucursal) {
											if (values.sucursalId === node._id) {
												setApiToken(node, setFieldValue);
											}
										}
									})}
									{branchOffices?.map((node) => (
										<MenuItem
											key={node._id}
											value={node._id}
											node={node}
											onClick={() => {
												setApiToken(node, setFieldValue);
											}}
										>
											{node.name}
										</MenuItem>
									))}
								</Field>
							</div>
							<div className='ticketData_form--inputGroup'>
								<FormLabel required>Total</FormLabel>
								<Field
									name='total'
									as={NumericFormat}
									customInput={TextField}
									value={values.total}
									fullWidth
									placeholder='Ejemplo: 2,999.99'
									id='total'
									data-testid='total'
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>$</InputAdornment>
										),
										inputProps: { inputMode: 'decimal' },
									}}
									onKeyDown={(e) => {
										if (e.key === 'unidentified') {
											e.preventDefault();
										}
										if (e.key === '-') {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										setFieldValue(
											'total',
											parseFloat(e.target.value.replace(/,/g, ''))
										);
									}}
									error={errors.total && touched.total}
									helperText={touched.total && errors.total}
									thousandSeparator=','
									decimalScale={2}
								/>
							</div>
							<div className='ticketData_form--checkbox'>
								<Field name='acceptedPolicies' type='checkbox'>
									{({ field }) => (
										<FormControlLabel
											control={
												<Checkbox
													{...field}
													required
													checked={values.acceptedPolicies}
												/>
											}
											label={
												<span>
													<span>He leído y estoy de acuerdo con la </span>
													<Link
														data-testid='linkPrivacyPolicies'
														href=' '
														onClick={(e) => {
															e.preventDefault();
															TogglePrivacyPolicyModal();
														}}
													>
														política de privacidad.
													</Link>
												</span>
											}
											sx={{ marginRight: 0 }}
										/>
									)}
								</Field>
							</div>
							<StepperButtons isValid={isValid} values={values} />
						</Box>
					)}
				</Formik>
			</Grid>
			<PrivacyPolicyModal />
		</Grid>
	);
}
