import React, { useContext, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import BillingConfigurationContext from '../../Context/BillingConfiguration/BillingConfigurationContext';

export default function SimpleBackdrop() {
	const { backdropController } = useContext(BillingConfigurationContext);

	useEffect(() => {
		if (backdropController) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [backdropController]);

	return (
		<div>
			<Backdrop
				data-testid='backdrop'
				sx={{
					color: '#fff',
					zIndex: (theme) => theme.zIndex.drawer + 1,
					overflow: 'hidden',
				}}
				open={backdropController}
			>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
}
