import { FILLING_DATA } from '../types';

export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case FILLING_DATA:
			return {
				...state,
				menuColor: `${payload.header.menuColor}`,
				address: payload.footer.contact.address,
				email: payload.footer.contact.email,
				phone: payload.footer.contact.phone,
				socialMedia: payload.footer.socialMedia,
				companyLogoFooter: payload.footer.companyLogoFooter
					? `${process.env.REACT_APP_ASSETS_URL}/${payload.footer.companyLogoFooter.level}/${payload.footer.companyLogoFooter.key}`
					: '',
				backgroundColorHeader: payload.header.backgroundColorHeader,
				backgroundColorFooter: payload.footer.backgroundColorFooter,
				textColorFooter: payload.footer.textColorFooter,
				primaryColor: payload.header.componentsColor,
			};

		default:
			return state;
	}
};
