import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Construction, ErrorOutline } from '@mui/icons-material';

import Footer from '../../Components/Footer/Footer';

export default function Maintenance() {
	return (
		<Stack justifyContent='center' alignItems='stretch' spacing={5}>
			<Box
				sx={{
					height: { md: '75vh', xs: 'calc(50vh - 50px)' },
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					textAlign: 'center',
				}}
			>
				<Typography
					sx={{
						fontWeight: '300 !important',
						typography: { md: 'h3', xs: 'h6' },
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<ErrorOutline
						sx={{ fontSize: { md: '3rem', xs: '1.5rem' }, marginRight: '4px' }}
					/>
					Página en mantenimiento
				</Typography>
				<Construction
					sx={{ fontSize: { md: '16rem', xs: '8rem' }, color: 'primary.main' }}
				/>

				<Typography
					sx={{
						typography: { md: 'h5', xs: 'h6' },
					}}
				>
					Contáctenos para más información
				</Typography>
				<Typography
					sx={{
						typography: { md: 'body1', xs: 'body2`' },
						opacity: 0.75,
						fontSize: { md: '1rem', xs: '0.8rem' },
					}}
				>
					(La información de contacto la encontrará en el pie de página)
				</Typography>
			</Box>
			<Box
				sx={{
					bottom: 0,
					position: 'absolute',
					width: '100%',
					flexGrow: 1,
				}}
			>
				<Footer />
			</Box>
		</Stack>
	);
}
