import React, { useContext, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import './FAQs.css';
import FAQsContext from '../../Context/FAQs/FAQsContext';
import BillingConfigurationContext from '../../Context/BillingConfiguration/BillingConfigurationContext';

export default function FAQs() {
	const { expanded, frequentQuestions, handleChangeAccordion } =
		useContext(FAQsContext);
	const { defaultFaqs } = useContext(BillingConfigurationContext);

	const FAQs = defaultFaqs.concat(frequentQuestions);
	return (
		<div className='FAQs__container' data-testid='faqs'>
			<Typography variant='h4' color='primary'>
				Preguntas frecuentes
			</Typography>
			<div className='FAQs__accordion__container'>
				{FAQs?.map((faq, index) => (
					<Accordion
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						expanded={expanded === index}
						onChange={handleChangeAccordion(index)}
						data-testid={`faqs_accordion_${index}`}
					>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography
								sx={{ flexShrink: 0 }}
								style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									width: '100%',
								}}
							>
								{faq.question}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography sx={{ color: 'text.secondary' }}>
								{faq.answer}
							</Typography>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</div>
	);
}
