import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import BillingConfigurationContext from '../Context/BillingConfiguration/BillingConfigurationContext';
import CustomizationContext from '../Context/Customization/CustomizationContext';
import baseUrl from '../config/index';

export default function Theme({ children }) {
	const {
		primaryColor,
		secondaryColor,
		thirdColor,
		white,
		backgroundColorHeader,
		backgroundColorFooter,
		textColorFooter,
		menuColor,
	} = useContext(CustomizationContext);

	const { findBillingDomainByActualDomain } = useContext(
		BillingConfigurationContext
	);

	useEffect(() => {
		findBillingDomainByActualDomain(baseUrl.baseUrl);
	}, []);

	const theme = createTheme({
		typography: {
			fontFamily: ['Sora', 'sans-serif'].join(','),
		},
		palette: {
			primary: {
				main: primaryColor || '#FF8623',
				contrastText: white,
			},
			secondary: {
				main: secondaryColor || '#717E8A',
				contrastText: white,
			},
			third: {
				main: thirdColor || '#202427',
				contrastText: white,
			},
			white: {
				main: white,
				main2: '#c25fbd',
			},
			neutral: {
				main: '#9b9fa3',
				contrastText: white,
			},
			backgroundColorHeader: {
				main: backgroundColorHeader,
			},
			backgroundColorFooter: {
				main: backgroundColorFooter,
			},
			textColorFooter: {
				main: textColorFooter,
			},
			menuColor: {
				main: menuColor,
			},
		},
		breakpoints: {
			values: {
				xs: 0,
				xsm: 400,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
