import React from 'react';

function FormatPhoneNumber(phoneNumber) {
	// Elimina todos los caracteres no numéricos del número de teléfono
	const cleaned = `${phoneNumber}`.replace(/\D/g, '');

	// Aplica el formato deseado (por ejemplo, (123) 456-7890)
	const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
		3,
		6
	)}-${cleaned.slice(6, 10)}`;

	return formatted;
}

export default FormatPhoneNumber;
