import React from 'react';
import { Paper, Container } from '@mui/material';
import { Element } from 'react-scroll';
import BillingStepper from '../BillingStepper/BillingStepper';
import './BillingFormCard.css';
import SimpleBackdrop from '../Backdrop/Backdrop';

export default function BillingFormCard() {
	return (
		<div>
			<Element name='billingFinished' className='element'>
				<Paper elevation={6} className='billingFormCard__container'>
					<Container>
						<BillingStepper />
					</Container>
				</Paper>
			</Element>
			<SimpleBackdrop />
		</div>
	);
}
