/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// https://duncanleung.com/aws-amplify-aws-exports-js-typescript/
// https://github.com/aws-amplify/docs/issues/766
// https://medium.com/@mlombog/aws-exports-js-ff4138678140

const {
	REACT_APP_PROJECT_REGION,
	REACT_APP_COGNITO_IDENTITY_POOL_ID,
	REACT_APP_COGNITO_REGION,
	REACT_APP_USER_POOLS_ID,
	REACT_APP_USER_POOLS_WEB_CLIENT_ID,
	REACT_APP_ENDPOINT_URL,
} = process.env;

const awsmobile = {
	aws_project_region: REACT_APP_PROJECT_REGION,
	aws_cognito_identity_pool_id: REACT_APP_COGNITO_IDENTITY_POOL_ID,
	aws_cognito_region: REACT_APP_COGNITO_REGION,
	aws_user_pools_id: REACT_APP_USER_POOLS_ID,
	aws_user_pools_web_client_id: REACT_APP_USER_POOLS_WEB_CLIENT_ID,
	oauth: {},
	aws_cloud_logic_custom: [
		{
			name: 'SiCSAPI',
			endpoint: 'https://api-sics.dev.sics.microsipnube.com',
			region: 'us-east-1',
		},
		{
			name: 'BillingAPI',
			endpoint: REACT_APP_ENDPOINT_URL,
			region: 'us-east-1',
		},
	],
	aws_appsync_authenticationType: 'AWS_IAM',
};
export default awsmobile;
