import React from 'react';

function SvgComponent({ path, width, height, fill }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			shapeRendering='geometricPrecision'
			textRendering='geometricPrecision'
			imageRendering='optimizeQuality'
			fillRule='evenodd'
			clipRule='evenodd'
			viewBox='0 0 512 350.3'
			fill={fill}
			width={width}
			height={height}
		>
			<path d={path} />
		</svg>
	);
}

export default SvgComponent;
