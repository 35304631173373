import {
	GET_DATA_FROM_ACTUAL_DOMAIN,
	GET_DATA_FROM_SUBSCRIPTION_ID,
	RESET_VALUES,
	SET_DEFAULT_FAQS,
	SET_DEFAULT_PRIVACY_NOTICE,
	TOOGLE_LOADING_DATA,
	GET_NODES_BY_SUBSCRIPTION_ID,
} from '../types';

export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case GET_DATA_FROM_ACTUAL_DOMAIN:
			return {
				...state,
				data: payload,
			};
		case GET_DATA_FROM_SUBSCRIPTION_ID:
			// localStorage.setItem('data', JSON.stringify(payload));
			return {
				...state,
				subscriptionData: payload,
				subscriptionId: payload?.subscriptionId,
			};
		case TOOGLE_LOADING_DATA:
			return {
				...state,
				loadingData: payload,
			};
		case RESET_VALUES:
			return {
				...state,
				data: payload,
				subscriptionData: payload,
			};
		case SET_DEFAULT_FAQS:
			return {
				...state,
				defaultFaqs: payload,
			};
		case SET_DEFAULT_PRIVACY_NOTICE:
			return {
				...state,
				defaultPrivacyNotice: payload,
			};
		case GET_NODES_BY_SUBSCRIPTION_ID:
			return {
				...state,
				nodes: payload,
			};

		case 'TOOGLE_BACKDROP':
			return {
				...state,
				backdropController: payload,
			};
		default:
			return state;
	}
};
