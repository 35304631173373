import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { scroller } from 'react-scroll';

import BillingContext from '../../Context/Billing/BillingContext';

export default function StepperButtons({ isValid, values }) {
	const {
		activeStep,
		handleNext,
		handleBack,
		captchaIsValid,
		submittingData,
		sendBillingData,
		isLoadingRecaptchaValidation,
	} = useContext(BillingContext);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				paddingTop: '16px',
			}}
			data-testid='stepperButtons'
			id='billingFinished'
		>
			{activeStep >= 1 ? (
				<Button
					color='neutral'
					variant='contained'
					disabled={activeStep === 0}
					onClick={() => {
						handleBack();
						scroller.scrollTo('billingFinished', {
							duration: 500,
							delay: 0,
							smooth: 'easeInOutQuart',
						});
					}}
					sx={{ mr: 1 }}
				>
					Atrás
				</Button>
			) : null}
			<Box sx={{ flex: '1 1 auto' }} />
			{activeStep === 2 ? (
				<Tooltip
					title={
						// eslint-disable-next-line no-nested-ternary
						isLoadingRecaptchaValidation && !captchaIsValid
							? 'Verificando captcha. Espere un momento.'
							: !captchaIsValid
							? 'Para facturar se necesita validar el Captcha'
							: ''
					}
					followCursor
				>
					<span>
						<Button
							onClick={async () => {
								await sendBillingData(values);
								scroller.scrollTo('billingFinished', {
									duration: 500,
									delay: 0,
									smooth: 'easeInOutQuart',
									offset: -100,
								});
							}}
							disabled={!captchaIsValid}
							variant='contained'
						>
							{submittingData ? (
								<Box sx={{ display: 'flex' }}>
									<CircularProgress size={24} color='white' />
								</Box>
							) : (
								<>
									{isLoadingRecaptchaValidation ? (
										<Box sx={{ display: 'flex' }}>
											<CircularProgress size={24} color='white' />
										</Box>
									) : (
										'Facturar'
									)}
								</>
							)}
						</Button>
					</span>
				</Tooltip>
			) : (
				<Tooltip
					title={
						!isValid || !values.acceptedPolicies
							? 'Para continuar se necesita llenar el formulario correctamente'
							: ''
					}
					followCursor
					data-testid='stepperButton_tooltip'
				>
					<span>
						<Button
							onClick={() => {
								handleNext(values);
								scroller.scrollTo('billingFinished', {
									duration: 500,
									delay: 0,
									smooth: 'easeInOutQuart',
								});
							}}
							disabled={isValid === false || values.acceptedPolicies === false}
							variant='contained'
						>
							Siguiente
						</Button>
					</span>
				</Tooltip>
			)}
		</Box>
	);
}
