import { KeyboardArrowUp, Menu } from '@mui/icons-material';
import { Fab, Fade, useScrollTrigger, Zoom } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { scroller } from 'react-scroll';
import './ButtonToTop.css';

function ScrollTop(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 150,
	});
	return <Zoom in={trigger}>{children}</Zoom>;
}

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired,
	// eslint-disable-next-line react/require-default-props
	window: PropTypes.func,
};

export default function ButtonToTop(props) {
	return (
		<ScrollTop {...props}>
			<Fab
				className='fab'
				color='primary'
				aria-label='scrollToTop'
				onClick={() => {
					scroller.scrollTo('home', {
						duration: 500,
						delay: 0,
						smooth: 'easeInOutQuart',
						offset: -100,
					});
				}}
			>
				<KeyboardArrowUp fontSize='large' />
			</Fab>
		</ScrollTop>
	);
}
