/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from 'react';
import { Alarm, CheckCircle, HighlightOff } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import BillingContext from '../../Context/Billing/BillingContext';
import './BillingFinished.css';

export default function BillingFinished() {
	const { initialValues, getInvoiceById, toogleInvoice, invoiceStatus } =
		useContext(BillingContext);
	const { invoiceId } = initialValues;
	const [invoiceDelayed, setInvoiceDelayed] = useState(false);
	const [invoiceData, setInvoiceData] = useState({});

	useEffect(() => {
		let requestCounter = 0;
		const intervalId = setInterval(() => {
			if (requestCounter <= 5) {
				getInvoiceById(invoiceId)
					.then((response) => {
						clearInterval(intervalId);
						toogleInvoice(true);
						setInvoiceData(response);
					})
					.catch((error) => {
						throw error;
					});
			}
			if (requestCounter === 6) {
				clearInterval(intervalId);
				toogleInvoice(true);
				setInvoiceDelayed(true);
			}
			requestCounter++;
		}, 10000);
	}, []);

	return (
		<Grid container spacing={2} className='BillingFinished__container '>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					margin: '0 auto',
					textAlign: 'center',
				}}
			>
				<Typography
					variant='h5'
					sx={{
						padding: '8px',
					}}
				>
					{!invoiceStatus ? (
						'Solicitud de factura enviada'
					) : (
						<>
							{invoiceDelayed ? (
								<>
									¡Lo sentimos!
									<Typography
										sx={{
											paddingTop: '8px',
										}}
									>
										Tu solicitud está tardando más de lo esperado.
									</Typography>
								</>
							) : (
								<>
									{invoiceData?.status === 'done'
										? 'Factura realizada'
										: 'No fue posible realizar la factura'}
								</>
							)}
						</>
					)}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						height: '200px',
					}}
				>
					{!invoiceStatus ? (
						<Box sx={{ width: { xs: 125, sm: 150, md: 180 }, margin: '1rem' }}>
							<CircularProgress size='100%' color='primary' />
						</Box>
					) : (
						<>
							{invoiceDelayed ? (
								<Alarm
									sx={{ fontSize: { xs: 150, sm: 180, md: 200 } }}
									color='primary'
								/>
							) : (
								<>
									{invoiceData?.status === 'done' ? (
										<CheckCircle
											sx={{ fontSize: { xs: 150, sm: 180, md: 200 } }}
											color='primary'
										/>
									) : (
										<HighlightOff
											sx={{ fontSize: { xs: 150, sm: 180, md: 200 } }}
											color='primary'
										/>
									)}
								</>
							)}
						</>
					)}
				</Box>
				{!invoiceStatus ? (
					<>
						<Typography
							sx={{
								padding: '8px',
							}}
						>
							Su solicitud se está procesando...
						</Typography>
					</>
				) : (
					<>
						{invoiceDelayed ? (
							<>
								<Typography
									sx={{
										paddingTop: '8px',
									}}
								>
									En cuanto su factura esté lista la enviaremos al correo:
								</Typography>
								<Typography color='primary'>{initialValues.correo}</Typography>
							</>
						) : (
							<>
								<Typography
									sx={{
										padding: '8px',
									}}
								>
									{invoiceData?.status === 'done' ? (
										'La factura será enviada al siguiente correo electrónico: '
									) : (
										<>
											{invoiceData.message?.split('\\n').map((line, i) => (
												<span key={i}>
													{line}
													<br />
												</span>
											))}
										</>
									)}
								</Typography>
								{invoiceData?.status === 'done' && (
									<Typography color='primary'>
										{initialValues.correo}
									</Typography>
								)}
							</>
						)}
					</>
				)}
			</Grid>
		</Grid>
	);
}
