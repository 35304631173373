import React, { useContext } from 'react';
import { Menu } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import './FABMenu.css';
import HeaderContext from '../../Context/Header/HeaderContext';

function ScrollTop(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 150,
	});
	return <Zoom in={trigger}>{children}</Zoom>;
}

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired,
	// eslint-disable-next-line react/require-default-props
	window: PropTypes.func,
};
export default function FABMenu(props) {
	const { toogleSidebar } = useContext(HeaderContext);

	return (
		// eslint-disable-next-line no-undef
		<ScrollTop {...props}>
			<Fab
				data-testid='menuButton'
				className='fab2 menuButton'
				color='primary'
				aria-label='menuButton'
				onClick={() => {
					toogleSidebar();
				}}
			>
				<Menu fontSize='medium' />
			</Fab>
		</ScrollTop>
	);
}
