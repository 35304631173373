import { FILLING_DATA, HANDLE_CHANGE_ACCORDION } from '../types';

export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case HANDLE_CHANGE_ACCORDION:
			return {
				...state,
				expanded: payload,
			};
		case FILLING_DATA:
			return {
				...state,
				frequentQuestions: payload,
			};

		default:
			return state;
	}
};
